import { NavigateFunction } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";
let navigate: NavigateFunction | null = null,
  store: any = null;

export const injectStore = (_store: any) => {
  store = _store;
};
export const injectNavigate = (v: NavigateFunction) => {
  navigate = v;
};
export const getStore = (): any => {
  return store;
};
export const getNavigate = (): NavigateFunction => {
  return navigate as unknown as NavigateFunction;
};
export const svgStringToPng = (svgString: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    // 创建一个新的 Image 对象
    const img = new Image();

    // 将 SVG 字符串转为 Data URL
    const svgBlob = new Blob([svgString], {
      type: "image/svg+xml;charset=utf-8",
    });
    const url = URL.createObjectURL(svgBlob);

    // 设置 Image 的源为 Data URL
    img.onload = function () {
      // 创建一个 Canvas
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx!.drawImage(img, 0, 0);

      // 转换 Canvas 为 PNG
      const pngDataUrl = canvas.toDataURL("image/png");

      // 释放 URL 对象
      URL.revokeObjectURL(url);

      // 调用回调函数并传递 PNG 数据
      resolve(pngDataUrl);
    };

    img.onerror = function () {
      console.error("Image loading error");
      URL.revokeObjectURL(url);
      reject("Image loading error");
    };

    // 触发 Image 加载
    img.src = url;
  });
};
export const dataURLToFile = (dataUrl: string, fileName: string) => {
  // 解码数据 URL
  const arr = dataUrl.split(",") as any;
  const mime = arr[0].match(/:(.*?);/)[1]; // 获取 MIME 类型
  const bstr = atob(arr[1]); // 解码 base64 字符串
  let n = bstr.length;
  const u8arr = new Uint8Array(n); // 创建 Uint8Array

  // 将二进制数据填充到 Uint8Array 中
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  // 创建 Blob 对象
  const blob = new Blob([u8arr], { type: mime });

  // 创建 File 对象
  return new File([blob], fileName, { type: mime });
};
export const generateRandomId = () => {
  return nanoid();
};
