import { clearUserInfo } from "@/store/slices/user";
import { getNavigate, getStore } from "@/utils";
import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import toast from "react-hot-toast";
const request = axios.create({
  baseURL: process.env.REACT_APP_URL,
  timeout: 100000,
});
const requestInterceptor = (config: InternalAxiosRequestConfig) => {
  config.headers["tenant-id"] = 1;
  const store = getStore();
  const accessToken = store.getState().user.accessToken;
  if (accessToken) {
    config.headers["Authorization"] = accessToken;
  }
  return config;
};
const responseInterceptor = (response: AxiosResponse) => {
  if (response.status === 200) {
    if (response.data.code === 401) {
      const store = getStore();
      store.dispatch(clearUserInfo());
      const navigate = getNavigate();
      navigate && navigate("/login");
    } else if (response.data && response.data.code === 0) {
      return response.data;
    } else {
      toast.error(response.data.msg || "Request Failed");
      throw response.data;
    }
  } else {
    toast.error("Newwork Error");
  }
};
request.interceptors.request.use(requestInterceptor);
request.interceptors.response.use(responseInterceptor);
export default request;
