import { createSlice } from "@reduxjs/toolkit";

const strategySlice = createSlice({
  name: "strategy",
  initialState: {
    chartSvgInfo: "",
    cacheKeyMap: {} as Record<string, string>,
  },
  reducers: {
    setChartSvgInfo(state, action) {
      state.chartSvgInfo = action.payload;
    },
    setCacheKey(state, action) {
      const { key, value } = action.payload;
      state.cacheKeyMap[key] = value;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setChartSvgInfo, setCacheKey } = strategySlice.actions;

export default strategySlice.reducer;
