import { IRouteObject } from "../model";

export const searchRoute = (
  path: string,
  routes: IRouteObject[] = []
): IRouteObject => {
  let result: IRouteObject = {};
  for (let item of routes) {
    if (item.path === path) return item;
    if (item.children) {
      const res = searchRoute(path, item.children);
      if (Object.keys(res).length) result = res;
    }
  }
  return result;
};
