import React, { Suspense } from "react";
import { Box, CircularProgress } from "@mui/material";

/**
 * @description 路由懒加载
 * @param {Element} Comp 需要访问的组件
 * @returns element
 */
const lazyLoad = (Comp: React.LazyExoticComponent<any>): React.ReactNode => {
  return (
    <Suspense
      fallback={
        <Box
          sx={{
            m: 3,
            textAlign: "center",
          }}
        >
          <CircularProgress size={30} />
        </Box>
      }
    >
      <Comp />
    </Suspense>
  );
};

export default lazyLoad;
