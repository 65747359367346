import request from ".";
import { SmsScene } from "../enums";
import { IResponseData } from "./model/request";
import { EmailLoginParam, EmailRegisterParam, ILoginParam } from "./model/user";
/**登录 */
export function login(info: ILoginParam) {
  return request.post<IResponseData>("/login", info);
}
export function logout() {
  return request.post<IResponseData>("/app-api/member/auth/logout");
}
/** 邮箱登录 */
export function emailLogin(data: EmailLoginParam) {
  return request.post<IResponseData, IResponseData>(
    "/app-api/member/auth/emailLogin",
    data
  );
}
/** 邮箱注册 */
export function emailRegister(data: EmailRegisterParam) {
  return request.post<IResponseData, IResponseData>(
    "/app-api/member/auth/emailRegister",
    data
  );
}
/**获取用户信息 */
export function getUserInfo() {
  return request.get<IResponseData, IResponseData>("/app-api/member/user/get");
}
export function sendSmsCode(data: { mobile: string; scene: SmsScene }) {
  return request.post<IResponseData>(
    "/app-api/member/auth/send-sms-code",
    data
  );
}
export function refreshToken() {
  return request.post<IResponseData>("/app-api/member/auth/refresh-token");
}
