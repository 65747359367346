import { BasicComponentProps } from "@/types/index";
import clsx from "clsx";
import { memo, ReactNode } from "react";

interface linearGradientTextProps extends BasicComponentProps {
  children: ReactNode;
}
const LinearGradientTitle: React.FC<linearGradientTextProps> = memo((props) => {
  return (
    <div
      style={{
        background: "linear-gradient(90deg, #7D76FD 0%, #63B2FC 100%)",
      }}
      className={clsx(
        "md:text-5xl text-2xl font-bold !bg-clip-text text-transparent !leading-[1.5]",
        props.className
      )}
    >
      {props.children}
    </div>
  );
});
export default LinearGradientTitle;
