import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import Login from "../pages/login/Login";
import { IRouteObject } from "./model";
import lazyLoad from "./utils/lazyload";
import React from "react";
import LayoutComponent from "../components/layout";
import Home from "../pages/home/index";
import EdgestUserProfile from "@/pages/Edgest/components/EdgestUserProfile";
import SparkCardList from "@/pages/Edgest/components/SparkCardList";

// * 导入所有router
//@ts-ignore
// const metaRouters = import.meta.globEager("./modules/*.tsx");

// // * 处理路由
// export const routerArray: IRouteObject[] = [];
// Object.keys(metaRouters).forEach((item) => {
//   Object.keys(metaRouters[item]).forEach((key: any) => {
//     routerArray.push(...metaRouters[item][key]);
//   });
// });
export const rootRouter: IRouteObject[] = [
  {
    path: "/login",
    element: <Login />,
    meta: {
      requiresAuth: false,
      title: "login",
      key: "login",
    },
  },
  {
    path: "/",
    element: <Home />,
    meta: {
      requiresAuth: false,
      title: "home",
    },
  },
  {
    path: "/privacy-policy",
    element: lazyLoad(
      React.lazy(() => import("@/pages/PrivacyPolicy/privacy"))
    ),
    meta: {
      requiresAuth: false,
      title: "privacy-policy",
    },
  },
  {
    path: "/terms-conditions",
    element: lazyLoad(
      React.lazy(() => import("@/pages/PrivacyPolicy/conditions"))
    ),
    meta: {
      requiresAuth: false,
      title: "terms-conditions",
    },
  },
  {
    element: <LayoutComponent />,
    children: [
      {
        path: "/edgest/",
        element: lazyLoad(React.lazy(() => import("@/pages/Edgest/Edgest"))),
        meta: {
          title: "",
          requiresAuth: true,
        },
        children: [
          {
            index: true, // 默认子路由，路径为 /edgest
            element: <SparkCardList />, // SparkCard 列表组件
          },
          {
            path: "profile/:userId", // 子路由，路径为 /edgest/profile/:userId
            element: <EdgestUserProfile />,
            meta: {
              title: "用户个人资料",
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "/strategy-markets",
        element: lazyLoad(React.lazy(() => import("@/pages/StrategyMarkets"))),
        meta: {
          title: "",
          requiresAuth: true,
        },
      },
      {
        path: "/dashboards",
        element: lazyLoad(
          React.lazy(() => import("@/pages/Dashboards/Dashboards"))
        ),
        meta: {
          title: "",
          requiresAuth: true,
        },
      },
      {
        path: "/bt-studio/*",
        element: lazyLoad(
          React.lazy(() => import("@/pages/BtStudio/BtStudio"))
        ),
        meta: {
          title: "",
          requiresAuth: true,
        },
      },
    ],
  },
  // ...routerArray,
  {
    path: "*",
    element: <Navigate to="/404" />,
  },
];
const Router = () => useRoutes(rootRouter as RouteObject[]);
export default Router;
