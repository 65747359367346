import { memo } from "react";
import LinearGradientTitle from "../components/linear-gradient-title";
import AnalysisReport from "./analysis-report";
import AiAgent from "./ai-agent";
import ti1 from "@/static/images/ti1.gif";

const AiPowered: React.FC = () => {
  return (
    <section className="md:mt-44 mt-24 ">
      <span className="slide-element">
        <LinearGradientTitle>
          <div className="font-normal">AI-Powered Quantitative</div>
        </LinearGradientTitle>
        <LinearGradientTitle className="mb-8">
          <div className="font-normal"> Trading Innovation</div>
        </LinearGradientTitle>
        <div className="md:text-5xl md:mb-20 text-2xl mb-8">
          <div className="font-normal">Your best AI quantitative partner.</div>
        </div>
      </span>
      <div className="w-full  md:h-[580px] items-center max-md:items-start overflow-hidden grid grid-cols-1 md:grid-cols-[1fr_2fr] border border-[#333333] rounded-[12px_12px_0_0]">
        <div className="flex flex-col justify-center h-full md:border-r border-[#333333] text-left sm:px-6 px-4 md:py-8 sm:py-6 py-4">
          <div className='md:text-3xl text-lg pl-3 relative before:content-[""] before:absolute before:left-0 before:top-0 before:h-full before:w-[2px] before:bg-[#DE393C] slide-element'>
            Intent Transformation
          </div>
          <div className="text-[#AAAAAA] mt-4 md:text-lg text-sm slide-element">
            Utilize natural language processing to automatically convert users'
            trading ideas into quantitative strategies.
          </div>
        </div>
        <img src={ti1} alt={""} className=" h-auto mr-5 max-md:!max-w-full" />
      </div>
      <AnalysisReport />
      <AiAgent />
    </section>
  );
};
export default memo(AiPowered);
