import { memo } from "react";
import LinearGradientTitle from "../components/linear-gradient-title";
import Marquee from "react-fast-marquee";
import { COMMENT_LIST } from "../data";
import { isMobile } from "@/utils/device";
const MessageBox = (props: { content: string }) => {
  let width = Math.floor(Math.random() * (510 - 450 + 1)) + 450;
  if (isMobile()) {
    width = Math.floor(Math.random() * (410 - 310 + 1)) + 310;
  }
  return (
    <div
      style={{
        maxWidth: width + "px",
      }}
      className=" cursor-pointer text-base line-clamp-3 md:text-2xl md:h-[412px] h-[150px] mx-2 rounded-xl text-[#A9A9A9] border border-[#2A2A2A] px-12 items-center flex justify-center messageBox"
    >
      {props.content}
    </div>
  );
};
const TrustByOver: React.FC = () => {
  return (
    <section className="md:mt-44 mt-24">
      <div className="md:text-5xl text-2xl mb-1 slide-element font-normal">
        Trusted by over
      </div>
      <LinearGradientTitle className="!font-normal md:mb-24 mb-10 slide-element font-normal">
        500+ top quant traders
      </LinearGradientTitle>

      <Marquee className="w-full" direction="right">
        <div className="flex text-left">
          {COMMENT_LIST.filter((_, index) => index % 2 !== 0).map(
            (info, index) => (
              <MessageBox key={index} content={info}></MessageBox>
            )
          )}
        </div>
      </Marquee>

      <Marquee className="w-full mt-4">
        <div className="flex pl-4 text-left">
          {COMMENT_LIST.filter((_, index) => index % 2 == 0).map(
            (info, index) => (
              <MessageBox key={index} content={info}></MessageBox>
            )
          )}
        </div>
      </Marquee>
    </section>
  );
};
export default memo(TrustByOver);
