import { ANALYSIS_REPORT } from "../data";
import { memo } from "react";
import InfoTypography from "../components/info-typography";
import Report from "@/static/images/home/report.gif";
import Analysis from "@/static/images/home/analysis.gif";
import Algorithm from "@/static/images/home/algorithm.gif";

const AnalysisReport: React.FC = () => {
  const files = [Report, Analysis, Algorithm];
  return (
    <div className="grid border border-t-0 border-[#333333] md:grid-cols-3 grid-cols-1">
      {ANALYSIS_REPORT.map((item, index) => (
        <div
          className="md:p-14 sm:p-6 p-4 md:first:border-r md:last:border-l border-[#333333] text-left flex-col justify-items-center "
          key={item.title}
        >
          {index === 1 ? (
            // 外层容器，用于裁剪和控制缩放
            <div className="overflow-hidden h-[222px] w-full mb-5">
              <img
                src={files[index]}
                alt={item.title}
                className="h-[222px] max-md:mx-auto mb-5 object-cover"
              />
            </div>
          ) : (
            // 其他图片正常显示
            <img
              src={files[index]}
              alt={item.title}
              className="h-[222px] max-md:mx-auto mb-5 object-cover"
            />
          )}
          <InfoTypography
            color={item.color}
            title={item.title}
            content={item.content}
          />
        </div>
      ))}
    </div>
  );
};

export default memo(AnalysisReport);
