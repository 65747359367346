import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  user: { accessToken: string | null };
}

const Header: React.FC<HeaderProps> = ({ user }) => {
  const navigate = useNavigate();
  const handleHomePage = () => {
    navigate("/");
  };
  const handleLogin = () => {
    navigate("/login");
  };
  return (
    <div className="home_header">
      <div className="flex justify-between w-full mb-10 md:mb-28 h-12 mt-7">
        <img
          width={100}
          src="/logo.svg"
          alt="Logo"
          onClick={handleHomePage}
          style={{ cursor: "pointer" }}
        />

        {!user.accessToken && (
          <Button
            onClick={handleLogin}
            variant="outlined"
            sx={{
              color: "#fff",
              width: "99px",
              borderRadius: "50px",
              borderColor: "#5D5D5D",
            }}
          >
            Log in
          </Button>
        )}
      </div>
    </div>
  );
};

export default Header;
