import { TextField, Typography } from "@mui/material";
import { LoginBox, LoginComponentProps, UnderlineLink } from "./Login";
import { RoundedLoadingButton } from "@/components/basic";

const ForgetPassword: React.FC<LoginComponentProps> = (props) => {
  return (
    <LoginBox title='Forgot your password?'>
      <Typography component={"div"} sx={{ textAlign: "left", mb: 2 }}>
        Enter your email address and we will send you instructions to reset your
        password
      </Typography>
      <TextField
        sx={{ mb: 2 }}
        size='small'
        required
        fullWidth
        label='Email'
      ></TextField>
      <RoundedLoadingButton
        variant='contained'
        fullWidth
        sx={{ mb: 2, textTransform: "none" }}
      >
        Continue
      </RoundedLoadingButton>
      <UnderlineLink
        onClick={() => {
          props.onTypeChange && props.onTypeChange("login");
        }}
      >
        Return to log in screen
      </UnderlineLink>
    </LoginBox>
  );
};
export default ForgetPassword;
