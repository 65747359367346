import { memo } from "react";
import InfoTypography from "../components/info-typography";
import BoxGif from "@/static/images/box.gif";
import LinearGradientTitle from "../components/linear-gradient-title";
import { ZERO_KNOWLEDGE } from "../data";
import StrateGyGif from "@/static/images/strategy-tokenization.gif";
import ZeroGif from "@/static/images/zero-knowledge.gif";
import ProfitGif from "@/static/images/profit.gif";
import SmartGif from "@/static/images/smart-contract.gif";
const CorrProtocol: React.FC = () => {
  const gifList = [ZeroGif, StrateGyGif, SmartGif, ProfitGif];
  return (
    <section className="md:mt-44 mt-24">
      <span className="slide-element">
        <div className="flex justify-center items-center max-sm:flex-col">
          <div className="md:text-5xl text-2xl">
            Corr Protocol
            <span className="max-sm:hidden">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          </div>
          <LinearGradientTitle>
            <div className="font-normal">DeQuant Revolution</div>
          </LinearGradientTitle>
        </div>
        <div className="md:text-5xl text-2xl mt-8 mb-1">
          <div className="font-normal">
            Making Your Quantitative Trading Strategies
          </div>
        </div>
        <div className="md:text-5xl text-2xl md:mb-20 mb-10">
          Algo Truly Yours
        </div>
      </span>
      <div className="h-[289px] rounded-2xl border border-[#333333] w-full sm:px-8 px-4 md:px-16 flex items-center">
        <InfoTypography
          className="flex-1"
          color=" before:bg-[#DD19FB]"
          title="Multi-Party Computation (MPC)"
          content="Strategy creators can perform backtesting and analysis without revealing their own data and parameters, ensuring privacy during development."
        ></InfoTypography>
        <div className="flex ml-12">
          <img
            src={BoxGif}
            className="w-[200px] ml-4 aspect-square max-sm:hidden"
            alt=""
          />
          <img
            src={BoxGif}
            className="w-[200px] ml-4 aspect-square max-lg:hidden"
            alt=""
          />
          <img src={BoxGif} className="w-[200px] ml-4 aspect-square" alt="" />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 mt-6 items-center justify-center md:gap-4 gap-2 grid-rows-2">
        {ZERO_KNOWLEDGE.map((item, index) => (
          <div
            key={item.title}
            style={{
              background:
                "linear-gradient(120.29deg, rgba(0, 0, 0, 0.13) 50.08%, rgba(58, 58, 58, 0.13) 76.08%)",
            }}
            className="w-full flex flex-col justify-center items-center md:h-[575px] py-8 md:pt-16 px-4 sm:px-8 md:px-16 border border-[#333333] rounded-2xl"
          >
            <img
              className="w-[200px] md:w-[250px] h-auto mb-5  md:mb-10 md:mt-5"
              alt=""
              src={gifList[index]}
            />
            <InfoTypography {...item}></InfoTypography>
          </div>
        ))}
      </div>
    </section>
  );
};
export default memo(CorrProtocol);
