import { Alert, AlertProps, Snackbar, SnackbarProps } from "@mui/material";
import { createContext, useContext, useState } from "react";
const MessageContext = createContext({
  show: (props: MessageProps): void => {},
});
export interface MessageProps {
  severity: AlertProps["severity"];
  message: React.ReactNode;
  anchorOrigin?: SnackbarProps["anchorOrigin"];
}
export const useMessage = () => {
  return useContext(MessageContext);
};
const MessageProvider: React.FC<any> = (props) => {
  const [open, setOpen] = useState(false);
  const [messageProps, setMessageProps] = useState<MessageProps>(
    {} as MessageProps
  );
  const show = (props: MessageProps) => {
    props = {
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      ...props,
    };
    setMessageProps(props);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <MessageContext.Provider value={{ show }}>
      <Snackbar
        anchorOrigin={messageProps.anchorOrigin}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageProps.severity}
          variant='filled'
          sx={{ width: "100%" }}
        >
          {messageProps.message}
        </Alert>
      </Snackbar>
      {props.children}
    </MessageContext.Provider>
  );
};
export default MessageProvider;
