import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/user";
import strategyReducer from "./slices/strategy";
export const store = configureStore({
  reducer: {
    user: userReducer,
    strategy: strategyReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
