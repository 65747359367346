import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EmailLoginParam } from "../../apis/model/user";
import { emailLogin, getUserInfo } from "../../apis/user";

export interface CounterState {
  accessToken: string;
  refreshToken: string;
  userInfo?: {
    userId: number;
    accessToken: string;
    refreshToken: string;
    expiresTime: number;
    email: string;
    openid: string;
  };
}

const initialState: CounterState = {
  accessToken: "",
  refreshToken: "",
  userInfo: undefined,
};
export const userLogin = createAsyncThunk(
  "user/login",
  async (args: EmailLoginParam, thunkApi) => {
    try {
      const result = await emailLogin(args);
      return result.data;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  }
);
export const loadUserInfo = createAsyncThunk(
  "user/info",
  async (_, thunkApi) => {
    try {
      const result = await getUserInfo();
      thunkApi.dispatch(userSlice.actions.setUserInfo(result.data));
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setToken: (state, action) => {
      const { accessToken, refreshToken } = action.payload;
      if (accessToken) {
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
      }

      state.accessToken =
        accessToken || localStorage.getItem("accessToken") || "";
      state.refreshToken =
        refreshToken || localStorage.getItem("refreshToken") || "";
    },
    clearUserInfo(state) {
      state.userInfo = undefined;
      state.accessToken = "";
      state.refreshToken = "";
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.fulfilled, (state, action) => {
      userSlice.caseReducers.setUserInfo(state, action);
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      console.log(action.payload);
    });
  },
});

// Action creators are generated for each case reducer function
export const { setUserInfo, setToken, clearUserInfo } = userSlice.actions;

export default userSlice.reducer;
