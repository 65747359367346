import React, { memo } from "react";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { Button, Typography } from "@mui/material";

interface SparkCardProps {
  data: {
    creater: string;
    creater_img: string;
    create_time: string;
    spark_type: string;
    backtest_symbol: string;
    backtest_symbol_taget: string;
    backtest_interval: string;
    backtest_img: string;
    backtest_start_date: string;
    backtest_end_date: string;
    backtest_frees_setting: string;
    backtest_TR_result: string;
    backtest_SR_result: string;
    backtest_MDD_result: string;
    spark_index: string;
    spark_card_box_style?: string;
  };
}

const SparkCard: React.FC<SparkCardProps> = ({ data }) => {
  return (
    <div
      className={
        data.spark_card_box_style ? data.spark_card_box_style : "spark_card_box"
      }
    >
      <div
        className="spark_card_header"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={data.creater_img} width={50}></img>
          <div style={{ fontSize: "20px", fontWeight: "bold", marginLeft: 10 }}>
            {data.creater}
          </div>
          <div style={{ fontSize: "10px", marginLeft: 10, color: "gray" }}>
            {data.create_time}
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ fontSize: "12px", marginLeft: 10 }}>
            <span style={{ marginRight: 20 }}>{data.spark_type}</span>{" "}
            {data.backtest_symbol} {data.backtest_interval} |
            {data.backtest_symbol_taget}
          </div>
          <div style={{ marginLeft: 10, cursor: "pointer" }}>
            <FontAwesomeIcon
              style={{ margin: 10, paddingTop: 4 }}
              icon={faBookmark}
            />
          </div>
        </div>
      </div>
      <div className="spark_card_index" style={{ padding: 10 }}>
        {data.spark_index}
      </div>
      <div className="spark_card_result">
        <div>
          <img src={data.backtest_img} width={"100%"}></img>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            color: "gray",
            fontSize: "13px",
            marginBlock: 5,
          }}
        >
          <div>
            {data.backtest_start_date} - {data.backtest_end_date}
          </div>
          <div
            style={{
              display: "flex",
              width: "40%",
              justifyContent: "space-around",
            }}
          >
            <div>FEES : {data.backtest_frees_setting}%</div>
            <div>TR : {data.backtest_TR_result}%</div>
            <div>SR : {data.backtest_SR_result}</div>
            <div>MDD : {data.backtest_MDD_result}</div>
          </div>
        </div>
      </div>
      <div
        className="spark_card_footer"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <Button
          style={{
            fontSize: 12,
            padding: 3,
            paddingInline: 20,
            marginLeft: 4,
            backgroundColor: "#313271",
            color: "#fff",
            paddingTop: 4,
          }}
          variant="outlined"
        >
          analyse
        </Button>
      </div>
    </div>
  );
};

export default memo(SparkCard);
