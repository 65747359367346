import Marquee from "react-fast-marquee";
import P1 from "@/static/images/home/p1.png";
import P2 from "@/static/images/home/p2.png";
import P3 from "@/static/images/home/p3.png";
import P4 from "@/static/images/home/p4.png";
import P5 from "@/static/images/home/p5.png";
import P6 from "@/static/images/home/p6.png";
import P7 from "@/static/images/home/p7.png";
import { BasicComponentProps } from "@/types/index";
import clsx from "clsx";
import { Style } from "@mui/icons-material";

interface PartnerProps extends BasicComponentProps {}
const Partner: React.FC<PartnerProps> = (props) => {
  const list: string[] = [P1, P2, P3, P4, P5, P6, P7];
  return (
    <Marquee className={clsx("w-full", props.className)}>
      <div className="flex w-full" style={{ opacity: 0.3 }}>
        {list.map((item, index) => (
          <img
            key={index}
            className=" cursor-pointer max-md:w-[110px] w-[197px] h-auto"
            src={item}
            // width='197px'
            // height='62px'
            alt=""
          />
        ))}
      </div>
    </Marquee>
  );
};
export default Partner;
