import { RoundedLoadingButton } from "@/components/basic";
import { LoginBox, LoginComponentProps, UnderlineLink } from "./Login";
import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { EmailRegisterParam } from "@/apis/model/user";
import { useAppDispatch } from "@/hooks";
import { emailRegister } from "@/apis/user";
import { setUserInfo } from "@/store/slices/user";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
const SignUp: React.FC<LoginComponentProps> = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<
    EmailRegisterParam & { confirmPassword: string }
  >({
    email: "",
    password: "",
    verifyCode: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    email: false,
    password: false,
    confirmPassword: false,
  });
  const dispath = useAppDispatch();
  const handleSubmit = async () => {
    const _error = { ...error };
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email)) {
      _error.email = true;
    }
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*./_-])(?!.*\s).{8,}/.test(form.password)) {
      _error.password = true;
      _error.confirmPassword = true;
    }
    if (form.password !== form.confirmPassword) {
      _error.confirmPassword = true;
    }
    setError(_error);
    if (Object.values(_error).some(Boolean)) {
      return false;
    }
    setLoading(true);
    emailRegister(form)
      .then((res) => {
        dispath(setUserInfo({ ...res.data, email: form.email }));
        navigate("/bt-studio");
      })
      .catch((err) => {
        err.msg && toast(err.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clearError = () => {
    setError({
      email: false,
      password: false,
      confirmPassword: false,
    });
  };
  return (
    <LoginBox title='Sign Up'>
      {/* <RoundedButton
        sx={{
          mb: 2,
          textTransform: "none",
        }}
        fullWidth
        color='inherit'
        variant='outlined'
        startIcon={<GoogleIcon></GoogleIcon>}
      >
        <Box sx={{ fontSize: 13 }}>Sign in with Google</Box>
      </RoundedButton>
      <Divider sx={{ mb: 2 }}>OR</Divider> */}
      <Box component='form'>
        <TextField
          error={error.email}
          helperText={error.email ? "Invalid Email" : ""}
          sx={{ mb: 2 }}
          size='small'
          required
          fullWidth
          onChange={(event) => {
            setForm({
              ...form,
              email: event.target.value,
            });
            clearError();
          }}
          label='Email'
        ></TextField>
        <TextField
          error={error.password}
          helperText={
            error.password ? "The password must be at least 8 characters long and include uppercase and lowercase letters, numbers, and special characters." : ""
          }
          onChange={(event) => {
            setForm({
              ...form,
              password: event.target.value,
            });
            clearError();
          }}
          sx={{ mb: 2 }}
          size='small'
          required
          type='password'
          fullWidth
          label='Password'
        ></TextField>
        <TextField
          error={error.confirmPassword}
          helperText={
            error.confirmPassword
              ? error.password && error.confirmPassword
                ? "Password must be at least 6 characters"
                : "Passwords do not match"
              : ""
          }
          onChange={(event) => {
            setForm({
              ...form,
              confirmPassword: event.target.value,
            });
            clearError();
          }}
          sx={{ mb: 2 }}
          type='password'
          size='small'
          required
          fullWidth
          label='Confirm Password'
        ></TextField>
        {/* <FormControl
          sx={{ mb: 2, width: "100%" }}
          size='small'
          required
          fullWidth
          variant='outlined'
        >
          <InputLabel htmlFor='outlined-adornment-password'>
            Password
          </InputLabel>
          <OutlinedInput
            fullWidth
            id='outlined-adornment-password'
            type={true ? "text" : "password"}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  // onClick={handleClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  // onMouseUp={handleMouseUpPassword}
                  // edge='end'
                >
                  {true ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label='Password'
          />
        </FormControl> */}
        {/* <TextField
          sx={{ mb: 2 }}
          size='small'
          required
          fullWidth
          label='First name'
        ></TextField>
        <TextField
          sx={{ mb: 2 }}
          size='small'
          required
          fullWidth
          label='Last name'
        ></TextField>
        <CountrySelect></CountrySelect>
        <Typography
          component={"div"}
          sx={{ fontSize: 12, mt: 4, textAlign: "left" }}
        >
          By clicking “Continue” below, you agree to our{" "}
          <UnderlineLink>Terms of Service</UnderlineLink>
          and <UnderlineLink>Privacy Policy</UnderlineLink>
        </Typography> */}
        {/* <FormControlLabel
          control={<Checkbox />}
          label={
            <Typography
              component={"span"}
              sx={{ fontSize: 12, textAlign: "left" }}
            >
              By clicking “Continue” below, you agree to our{" "}
              <UnderlineLink>Terms of Service</UnderlineLink>
              and <UnderlineLink>Privacy Policy</UnderlineLink>
            </Typography>
          }
        ></FormControlLabel> */}
        <RoundedLoadingButton
          variant='contained'
          fullWidth
          loading={loading}
          onClick={handleSubmit}
          sx={{ mt: 2, textTransform: "none" }}
        >
          Continue
        </RoundedLoadingButton>

        <Typography
          sx={{
            color: "",
            ":hover": {},
            mt: 2,
          }}
          align='center'
        >
          Already have an account?
          <UnderlineLink
            onClick={(event) => {
              event.preventDefault();
              props.onTypeChange && props.onTypeChange("login");
            }}
          >
            Log in
          </UnderlineLink>
        </Typography>
      </Box>
    </LoginBox>
  );
};
export default SignUp;
