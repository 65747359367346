export const FAST_EFFICIENCY = [
  {
    title: "Fast  Efficiency",
    color: "before:bg-[#1FCFF1]",
    content:
      "By leveraging multi-computer clusters and distributed computing technology, our backtesting speed is 90% faster than traditional personal computers, allowing you to validate and optimize strategies faster.",
  },
  {
    title: "Non-code",
    color: "before:bg-[#9945FF]",
    content:
      "With creative fuzzy search and AI intent input, you can complete complex strategy designs without writing code, lowering the technical barrier and easily realizing your trading ideas.",
  },
  {
    title: "Confidentiality",
    color: "before:bg-[#19FB9B]",
    content:
      "Utilizing Multi-Party Computation (MPC) technology, the backend cannot access your strategy logic at all, ensuring your strategies are protected with the highest level of security.",
  },
  {
    title: "Multi Data",
    color: "before:bg-[#12EFFF]",
    content:
      "In addition to price data, we connect a wealth of non-price data such as news and social media sentiment, providing comprehensive data support to help you build better strategies.",
  },
];
export const ANALYSIS_REPORT = [
  {
    title: "AI Analysis Report",
    color: "before:bg-[#F4FF2B]",
    content:
      "Generate detailed strategy analysis and optimization reports to help users understand strategies.",
  },
  {
    title: "Preliminary Analysis",
    color: "before:bg-[#1FCFF1]",
    content:
      "Include future factor mining, strategy evaluation, and overfitting analysis to enhance strategy robustness.",
  },
  {
    title: "Optimization Algorithms",
    color: "before:bg-[#9945FF]",
    content:
      "Implement advanced techniques like genetic algorithms and Bayesian optimization to achieve automated strategy optimization.",
  },
];
export const STRATEGY_BUILDER = [
  {
    title: "Strategy Builder",
    content: "No Code, Easily Build Complex Strategies",
  },

  {
    title: "Strategy Management",
    content: "Efficiently Organize and Monitor Your Strategies",
  },
  {
    title: "Strategy Optimization",
    content: "AI-Powered Strategy Optimization",
  },
  {
    disabled: true,
    title: "Aggregated Strategies",
    content: "Integrate Multiple Strategies for Enhanced Trading Power",
  },
  {
    disabled: true,
    title: "Paper Trading",
    content: "Simulated Trading, Zero-Risk Strategy Testing",
  },
];
export const ZERO_KNOWLEDGE = [
  {
    title: "Zero-Knowledge Proof (ZKP)",
    content:
      " Before publishing, use ZKP technologies (e.g., zk-SNARKs or zk-STARKs) to generate proofs that verify the strategy's validity without disclosing execution logic, enhancing investor confidence.",
    color: " before:bg-[#19FB9B]",
  },
  {
    title: "Strategy Tokenization",
    content:
      "Package strategies into encrypted smart contracts and issue strategy tokens. Token holders share in execution profits, with the strategy logic remaining encrypted during execution for confidentiality.",
    color: "before:bg-[#FBD219]",
  },
  {
    title: "Smart Contract Automation",
    content:
      "Use ZKP-supported smart contracts to automatically execute trades on exchanges based on preset signals, achieving strategy automation.",
    color: "before:bg-[#F530FF]",
  },
  {
    title: "Profit Distribution Mechanism",
    content:
      " Smart contracts automatically calculate and distribute profits. ",
    color: "before:bg-[#00CCFF]",
  },
];
export const COMMENT_LIST = [
  "“Just tried Corrai's no-code strategy builder, feels like opening a new world! 🚀 Can implement complex strategies without coding, efficiency greatly improved! “",
  "“Corrai's AI strategy optimization is incredibly powerful! Genetic algorithms and Bayesian optimization take my strategies to the next level. 🔥 “",
  "“Corrai's Paper Trading feature is so practical, zero-risk strategy testing in real market conditions, perfect!”",
  "Corrai truly offers multi-dimensional data support! Besides price data, there's social media sentiment, etc. Strategy performance significantly improved.",
  "The era of no-code programming is truly here! Corrai lets someone like me without coding background easily build trading strategies",
  "The combination of smart contracts and multi-party computation technology makes Corrai's platform powerful.",
  "Corrai's business model is diverse, balancing subscriptions and commissions, clear revenue streams. 💡",
  "Strategy sharing and copy-trading features promote community collaboration and win-win. 🤝",
  "The visual strategy editor is user-friendly, excellent user experience.",
  "Rich data sources make my strategies more diverse. Corrai is my top choice for quantitative trading. 📊",
  "The strategy optimization tool is powerful, helping me seize many trading opportunities! 📈",
  "The perfect combination of AI and blockchain, Corrai's platform security gives me great peace of mind. 🔒 #FinTech #AI",
  "Corrai makes quantitative trading so simple, no coding required. A blessing for investors! 🚀",
  "Corrai no-code strategy platform is revolutionary, allowing me to create trading strategies effortlessly without coding!"
];
