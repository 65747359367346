// src/pages/Edgest/components/SparkCardList.tsx

import React from "react";
import SparkCard from "../SparkCard";
import { useOutletContext } from "react-router-dom";

interface SparkData {
  creater: string;
  creater_img: string;
  create_time: string;
  spark_type: string;
  backtest_symbol: string;
  backtest_symbol_taget: string;
  backtest_interval: string;
  backtest_img: string;
  backtest_start_date: string;
  backtest_end_date: string;
  backtest_frees_setting: string;
  backtest_TR_result: string;
  backtest_SR_result: string;
  backtest_MDD_result: string;
  spark_index: string;
}

interface OutletContext {
  sparkdata: SparkData[];
}

const SparkCardList: React.FC = () => {
  const { sparkdata } = useOutletContext<OutletContext>();

  return (
    <div
      style={{
        padding: "20px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {sparkdata.map((item, index) => (
        <SparkCard key={`SparkCard-${index}`} data={item} />
      ))}
    </div>
  );
};

export default SparkCardList;
