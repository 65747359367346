import { memo } from "react";
import { FAST_EFFICIENCY } from "../data";
import InfoTypography from "../components/info-typography";

const FastEfficiency: React.FC = () => {
  return (
    <div className="grid border-r border-b border-l border-[#333333] rounded-[0_0_12px_12px] lg:grid-cols-4  md:grid-cols-2 grid-cols-1 md:gap-20 gap-5  md:px-14 p-5 md:py-16 ">
      {FAST_EFFICIENCY.map((item) => (
        <InfoTypography
          key={item.title}
          title={item.title}
          color={item.color}
          content={item.content}
        ></InfoTypography>
      ))}
    </div>
  );
};
export default memo(FastEfficiency);
