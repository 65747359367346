import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../footer";

const LayoutComponent = () => {
  return (
    <div className='grid grid-rows-[1fr_auto]'>
      <Header></Header>
      <main id='main'>
        <Outlet></Outlet>
      </main>
      {/* <Footer /> */}
    </div>
  );
};
export default LayoutComponent;
